import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { LocalizedPageProps } from '../utils/types';
import { HeroText } from '../components/partials/common/hero';
import RepeatingSections from '../components/sections/repeating-section';
import {FullWidthSliderCenterSpot } from '../components/partials/common/elements/Slider';

const AboutUsPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('about-who-we-are');
  const { lang, alternateUrls } = pageContext;

  const hero: any = t('hero', {
    returnObjects: true,
  });

  const hero_slider: any = t('hero_slider', {
    returnObjects: true,
  });

  const sections: any = t('sections', {
    returnObjects: true,
  });

  return (
    <Layout pageContext={pageContext} mainClasses='background-light-full-purple' navClasses="background-light-full-purple">
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroText
        title={hero.title}
        text={hero.text}
        ctaUrl={hero.ctaUrl}
        ctaText={hero.ctaText}
        image={hero.image}
      />
      <FullWidthSliderCenterSpot classes="slider_bg_phone" slides={hero_slider} />

      <RepeatingSections data={sections}    />

      <p>{t('notFoundMessage')}</p>
    </Layout>
  );
};

export default AboutUsPage;

import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

import { InnerSection } from '../partials/common/elements/InnerSection';
import Button from '../ui/button';
import pages from '../../utils/pages';

const { getPageUrl } = pages;

const RepeatingSections: FC<{
  data: any;
  // eslint-disable-next-line react/require-default-props
  title?: string;
}> = ({ data, title }) => {
  const { t } = useTranslation('general');
  return (
    <>
      {title !== 'sectionsTitle' && title && (
        <div className="sections-title columns is-centered">
          <div className="column is-7 ">
            <h2 className="title title-large is-marginless text-centered text-black">
              {title}
            </h2>
          </div>
        </div>
      )}

      {Object.keys(data).map((key, i) => {
        const { classes } = data[key];
        return (
          <section
            key={key}
            className={`${classes} section repeating-section has-background-white`}
          >
            <InnerSection key={key} data={data[key]} />
            {i + 1 === Object.keys(data).length && isMobileOnly && (
              <div className="container has-text-centered">
                <Button
                  primary
                  size="large"
                  className="cta Xlast-innersection-cta"
                  to={
                    getPageUrl('ssu', t('key')) &&
                    getPageUrl('ssu', t('key')).url
                  }
                >
                  {t('freeTrial')}
                </Button>
              </div>
            )}
          </section>
        );
      })}
    </>
  );
};

export default RepeatingSections;
